import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    this.countryChange()
  }

  countryChange() {
    const country = document.querySelector('.js-venue-country-code-select').value
    const countriesWithMobileNumber = ['IN']

    const mobileFields = document.querySelector('.js-organisers-mobile-number-group')
    const ageFields = document.querySelector('.js-venue-age-fields-group')
    const publicClubFields = document.querySelector('.js-public-group')
    if (countriesWithMobileNumber.indexOf(country) != -1) {
      this.showElement(mobileFields)
      this.hideElement(ageFields)
      this.hideElement(publicClubFields, () => publicClubFields.querySelector('#club_public').checked = false)
    } else {
      this.hideElement(mobileFields)
      this.showElement(ageFields)
      this.showElement(publicClubFields)
    }
  }

  hideElement(element, clearFunc) {
    if(element) {
      element.classList.add('u-hidden')
      if(clearFunc) {
        clearFunc()
      }
    }
  }

  showElement(element) {
    if(element) {
      element.classList.remove('u-hidden')
    }
  }
}
