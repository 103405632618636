import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['englandAndWales', 'northernIreland', 'scotland', 'republicOfIreland', 'info']
    
    initialize() {
        this.hideAllInfo()
    }

    hideAllInfo() {
        this.infoTargets.forEach((t) => t.style.display = 'none')
    }

    displayInfo(event) {
        let infoTargets = {
            'england-and-wales': this.englandAndWalesTarget,
            'northern-ireland': this.northernIrelandTarget,
            'scotland': this.scotlandTarget,
            'republic-of-ireland': this.republicOfIrelandTarget
        }

        this.hideAllInfo()

        const infoTarget = event.currentTarget.selectedOptions[0].value
        infoTargets[infoTarget].style.display = 'block'
    }
}
