import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['results']

  updateSearchResults(url) {
    fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      credentials: 'same-origin',
    })
      .then(response => response.text())
      .then(html => {
        var div = document.createElement('div')
        div.innerHTML = html

        this.element.parentNode.replaceChild(div.firstChild, this.element)
      })
      .catch(error => {
        this.element.innerHTML = error
      })
  }

  showLoadingIcon() {
    this.resultsTarget.innerHTML = `
      <div class="computer">
        <div class="screen">
        </div>
      </div>
    `
  }

  radiusChanged(e) {
    this.showLoadingIcon()

    const url =
      this.data.get('url') + '&clubs_search[radius]=' + e.currentTarget.value
    this.updateSearchResults(url)
  }

  movePage(e) {
    e.preventDefault()

    this.showLoadingIcon()
    const url = e.currentTarget.href
    this.updateSearchResults(url)
  }
}
