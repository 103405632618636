import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'name',
    'nickname',
    'email',
    'profilePicture',
    'region',
    'content',
    'twitter',
  ]

  connect() {
    this.load()
  }

  load() {
    const lon = this.data.get('lon')
    const lat = this.data.get('lat')
    const country = this.data.get('country')
    if (country === 'GB') {

      this.getLocationsAt(lat, lon)
        .then(locationsJson => {
          const nutsCode = this.getNutsFromLocations(locationsJson.result)
          return this.getUkRegionalCoord(nutsCode)
        })
        .then(rc => {
          this.nameTarget.textContent = rc.name
          this.emailTarget.innerHTML = `<a href='mailto:${rc.email
            }' class='c-link c-link-regional-coordinator'>Email ${rc.nickname}</a>`
          this.profilePictureTarget.innerHTML = `<div class='c-coordinator__avatar c-coordinator__avatar--${rc.name
            .replace(' ', '-')
            .toLowerCase()}'>`
          this.regionTarget.textContent = rc.region
          this.twitterTarget.innerHTML = `<a href='${rc.twitter
            }' class='c-link c-link-regional-coordinator'>Find ${rc.nickname
            } on Twitter</a>`
        })
        .catch(error => {
          console.error('Failed to fetch Regional Coordinator', error)
          // TODO: log in sentry
        })
        .finally(this.contentTarget.classList.remove('u-hidden'))
    }

    if (country === 'IE') {
      this.updateRcOnPanel('Darren Bayliss')
    }

    if (country === "US") {
      this.updateRcOnPanel('Kevin Johnson', 'usa@codeclub.org', 'https://twitter.com/CodeClub_USA')
    }

    if (country === "IN") {
      this.updateRcOnPanel('Vasu', 'india@raspberrypi.org')
    }
  }

  updateRcOnPanel(rc_name, rc_email = null, rc_twitter = null) {
    const country = this.data.get('country')
    let obj = { IE: 'Ireland', US: 'the United States of America', IN: 'India' }
    this.contentTarget.classList.remove('u-hidden')
    this.nameTarget.textContent = rc_name
    this.regionTarget.innerHTML = obj[country]
    this.profilePictureTarget.innerHTML = `<div class='c-coordinator__avatar c-coordinator__avatar--${rc_name
      .replace(' ', '-')
      .toLowerCase()}'>`
    if (rc_email) {
      this.emailTarget.innerHTML = `<a class="c-link c-link-regional-coordinator" href="mailto: ${rc_email}">${rc_email}</a>`
    }
    if (rc_twitter) {
      this.twitterTarget.innerHTML = `<a class="c-link c-link-regional-coordinator" href="${rc_twitter}">Tweet Code Club</a>`
    }
  }



  getLocationsAt(lat, lon) {
    return fetch(`https://api.postcodes.io/postcodes?lon=${lon}&lat=${lat}&radius=2000`)
      .then(response => response.json())
      .catch(error => {
        console.error('Failed to fetch locations', error)
        // TODO: log error in Sentry
      })
  }

  getNutsFromLocations(locations) {
    let code
    if (locations.length > 1) {
      code = locations[0].codes.nuts
    }
    return code
  }

  getUkRegionalCoord(nutsCode) {
    return fetch(`${this.data.get('url')}/${nutsCode}`)
      .then(response => response.json())
      .catch(error =>
        console.error('Failed to fetch Regional Coordinator', error)
      )
  }
}
