/* eslint no-console:0 */
/* global gtag */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '../src/scss/application.scss'
import 'whatwg-fetch'
import '@stimulus/polyfills'

import { Application } from 'stimulus'
import Rails from 'rails-ujs'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

Rails.start()

const application = Application.start()
const context = require.context('controllers', true, /.js$/)
application.load(definitionsFromContext(context))

const initTrackedLinks = () => {
  const trackedLinks = document.querySelectorAll('a[data-action]')

  for (let trackedLink of trackedLinks) {
    let data = trackedLink.dataset

    if (data.action !== 'click') {
      continue
    }

    trackedLink.addEventListener(data.action, () =>
      gtag('event', data.action, {
        event_category: data.category,
        event_label: data.label,
      })
    )
  }
}

document.addEventListener('DOMContentLoaded', () => initTrackedLinks())
